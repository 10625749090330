import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlShape, useIntl } from 'react-intl';
import Modal from '../Modal/Modal';
import CompanySelectForm from '../../component/Admin/Company/CompanySelectForm/CompanySelectForm';
import { setSelectedCompany } from '../../store/company/actions';
import { SetUserSettingRequest } from '../../store/user-setting/service';
import { translate } from '../../utility/messageTranslator/translate';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import { Company } from '../../domain/Company';
import { UserSetting, UserSettingType } from '../../domain/UserSetting';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import CurrentAccount from '../CurrentAccount/CurrentAccount';
import { useNavigate } from 'react-router-dom';
import * as companyService from '../../store/company/service';
import * as userSettingService from '../../store/user-setting/service';
import { routes } from '../../config/Router/routes';
import { resetSavedFiltersStore, setTimezone } from '../../store/user/actions';

export type CompanySelectProps = {
  currentUser: User | null;
  companyOptions: Company[];
  selectedCompany: number | null;
  createdUserSetting: UserSetting | null;
  onCompanySelect: (companyId: number) => void;
  onSetSetting: (inputs: SetUserSettingRequest) => void;
  isAuthenticated: boolean;
  onCompanyOptionsFetch: (intl: IntlShape) => void;
  onSetTimezone: (timezone: string) => void;
  canSelectCompany: boolean;
  onResetSavedFiltersStore: () => void;
};

const CompanySelect = ({
  currentUser,
  companyOptions,
  selectedCompany,
  createdUserSetting,
  onCompanySelect,
  onSetSetting,
  isAuthenticated,
  onCompanyOptionsFetch,
  onSetTimezone,
  canSelectCompany,
  onResetSavedFiltersStore,
}: CompanySelectProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAutoSelectLoading, setIsAutoSelectLoading] = useState(false);
  const [company, setCompany] = useState<Company | undefined>(undefined);

  const intl = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && currentUser?.role && canSelectCompany) {
      onCompanyOptionsFetch(intl);
    }
  }, []);

  useEffect(() => {
    if (
      createdUserSetting?.type === UserSettingType.COMPANY &&
      isAutoSelectLoading &&
      companyOptions.length > 0
    ) {
      const companyOption = companyOptions[0];

      onCompanySelect(companyOption.id);
      onSetTimezone(companyOption.timezone);

      onCompanySelectSuccess();
      setIsAutoSelectLoading(false);
    }
  }, [createdUserSetting]);

  useEffect(() => {
    if (selectedCompany && companyOptions.length === 0) {
      return;
    }

    if (companyOptions.length === 1 && !selectedCompany) {
      const companyOption = companyOptions[0];

      setIsAutoSelectLoading(true);
      onSetSetting({
        type: UserSettingType.COMPANY,
        value: companyOption.id.toString(),
      });
    }
    const company = companyOptions.find(
      (companyOption) => companyOption.id === selectedCompany,
    );

    setCompany(company);
  }, [companyOptions, selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      setIsModalOpen(false);
    }
  }, [selectedCompany]);

  const onCompanySelectSuccess = () => {
    onResetSavedFiltersStore();

    setIsModalOpen(false);

    navigate(routes.logs.list);
  };

  const getCurrentCompany = () => {
    if (currentUser?.role === Roles.USER && companyOptions.length <= 1) {
      return null;
    }

    return (
      <CurrentAccount
        title={translate(intl, 'LAYOUT.NO_COMPANY_SELECTED')}
        subTitle={
          company
            ? `${translate(intl, 'LAYOUT.SELECTED_COMPANY')}:`
            : translate(intl, 'LAYOUT.CLICK_TO_SELECT_COMPANY')
        }
        companyName={company?.name}
        onClick={() => !isModalOpen && setIsModalOpen(true)}
      >
        <Modal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          title={translate(intl, 'LAYOUT.SELECT_COMPANY_TITLE')}
        >
          {isModalOpen && (
            <CompanySelectForm onSuccessSelect={onCompanySelectSuccess} />
          )}
        </Modal>
      </CurrentAccount>
    );
  };

  return (
    <>
      {getCurrentCompany()}
      <Modal
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={translate(intl, 'LAYOUT.SELECT_COMPANY_TITLE')}
      >
        {isModalOpen && (
          <CompanySelectForm onSuccessSelect={onCompanySelectSuccess} />
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  companyOptions: state.company.companyOptions,
  selectedCompany: state.company.selectedCompany,
  createdUserSetting: state.userSetting.createdUserSetting,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onCompanyOptionsFetch: (intl: IntlShape) =>
    dispatch(companyService.fetchCompanyOptions(intl)),
  onCompanySelect: (selectCompanyId: number) =>
    dispatch(setSelectedCompany(selectCompanyId)),
  onSetSetting: (inputs: SetUserSettingRequest) =>
    dispatch(userSettingService.setUserSetting(inputs)),
  onSetTimezone: (timezone: string) => dispatch(setTimezone(timezone)),
  onResetSavedFiltersStore: () => dispatch(resetSavedFiltersStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelect);
