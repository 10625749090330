import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import * as companyService from '../../store/company/service';

const LoginPage = React.lazy(
  () => import('../../pages/Public/LoginPage/LoginPage'),
);

const AdminsListPage = React.lazy(
  () => import('../../pages/Admin/Admin/AdminsListPage/AdminsListPage'),
);

const AdminCreatePage = React.lazy(
  () => import('../../pages/Admin/Admin/AdminCreatePage/AdminCreatePage'),
);

const AdminEditPage = React.lazy(
  () => import('../../pages/Admin/Admin/AdminEditPage/AdminEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

const DriversListPage = React.lazy(
  () => import('../../pages/Admin/Driver/DriversListPage/DriversListPage'),
);

const DriverEditPage = React.lazy(
  () => import('../../pages/Admin/Driver/DriverEditPage/DriverEditPage'),
);

const PasswordRemindPage = React.lazy(
  () => import('../../pages/Public/PasswordRemindPage/PasswordRemindPage'),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const VehiclesListPage = React.lazy(
  () => import('../../pages/Admin/Vehicle/VehiclesListPage/VehiclesListPage'),
);

const VehiclesEditPage = React.lazy(
  () => import('../../pages/Admin/Vehicle/VehicleEditPage/VehicleEditPage'),
);

const EldsListPage = React.lazy(
  () => import('../../pages/Admin/Eld/EldListPage/EldListPage'),
);

const Companies = React.lazy(
  () => import('../../pages/Admin/Company/CompaniesListPage/CompaniesListPage'),
);

const CompanyEdit = React.lazy(
  () => import('../../pages/Admin/Company/CompanyEdit/CompanyEdit'),
);

const OwnerCompanyEdit = React.lazy(
  () => import('../../pages/Admin/Company/CompanyEdit/CompanyEdit'),
);

const LogListPage = React.lazy(
  () => import('../../pages/Admin/Log/LogListPage/LogListPage'),
);

const LogPage = React.lazy(
  () => import('../../pages/Admin/Log/LogPage/LogPage'),
);

const LogEditPage = React.lazy(
  () => import('../../pages/Admin/Log/LogEditPage/LogEditPage'),
);

const ReportListPage = React.lazy(
  () => import('../../pages/Admin/Report/ReportListPage/ReportListPage'),
);

const FMCSAReportListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/FMCSAReport/FMCSAReportsListPage/FMCSAReportsListPage'
    ),
);

const CompanyUsersListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/CompanyUser/CompanyUsersListPage/CompanyUsersListPage'
    ),
);

const PasswordChangePage = React.lazy(
  () => import('../../pages/Admin/User/PasswordChangePage/PasswordChangePage'),
);

const UnidentifiedDrivingPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Log/UnidentifiedDrivingsPage/UnidentifiedDrivingsPage'
    ),
);

const CompanyUserCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Admin/CompanyUser/CompanyUserCreatePage/CompanyUserCreatePage'
    ),
);

const CompanyUserEditPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/CompanyUser/CompanyUserEditPage/CompanyUserEditPage'
    ),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onLanguagesInit: () => void;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  languages: Language[] | null;
  currentUser: User | null;
  selectedCompany: number | null;
  timezone: string;
  onCompanyFetch: (id: number) => void;
};

const ALLOWED_LOCALES = ['en'];

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  onLanguagesInit,
  selectedLocale,
  onSelectLocale,
  languages,
  currentUser,
  selectedCompany,
  timezone,
  onCompanyFetch,
}: Props) => {
  moment.tz.setDefault(timezone);

  const locale =
    window.location?.pathname?.substring(1)?.split('/')?.[0] ?? DEFAULT_LOCALE;
  const parsedLocale = (
    locale && ALLOWED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE
  ) as Locale;

  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(
      () => {
        onLogout();
      },
      difference.asMilliseconds() > 2147483647
        ? 2147483647
        : difference.asMilliseconds(),
    );

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (selectedCompany) {
      onCompanyFetch(selectedCompany);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (!parsedLocale) {
      onLanguageFetch(parsedLocale ?? DEFAULT_LOCALE);
      return;
    }

    moment.locale(parsedLocale === 'en' ? 'en-gb' : parsedLocale);
    onLanguageFetch(parsedLocale);
    onLanguagesInit();
    onSelectLocale(parsedLocale);
  }, [parsedLocale]);

  const getCurrentLanguage = () => {
    const foundLanguage = languages?.find(
      (singleLanguage) => singleLanguage.locale === selectedLocale,
    );

    return foundLanguage ?? language;
  };

  const mappedTranslations = getCurrentLanguage()?.translations?.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.login} element={<LoginPage />} />
          <Route path="*" element={<Navigate to={routes.login} />} />
          <Route path={routes.remind} element={<PasswordRemindPage />} />
          <Route path={routes.resetPassword} element={<PasswordResetPage />} />
        </>
      );
    }

    if (
      currentUser?.role &&
      [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MODERATOR, Roles.SUPPORT].includes(
        currentUser.role,
      )
    ) {
      return (
        <>
          {currentUser.role === Roles.SUPER_ADMIN && (
            <>
              <Route
                path={routes.translations}
                element={<TranslationsPage />}
              />
            </>
          )}
          {![Roles.SUPPORT].includes(currentUser.role) && (
            <>
              <Route
                path={routes.admins.create}
                element={<AdminCreatePage />}
              />
              <Route path={routes.admins.edit} element={<AdminEditPage />} />
              <Route path={routes.admins.list} element={<AdminsListPage />} />
              <Route
                path={routes.companyUsers.create}
                element={<CompanyUserCreatePage />}
              />
              <Route
                path={routes.companyUsers.edit}
                element={<CompanyUserEditPage />}
              />
            </>
          )}
          <Route
            path={routes.companyUsers.list}
            element={<CompanyUsersListPage />}
          />
          <Route path={routes.elds.list} element={<EldsListPage />} />
          <Route
            path={routes.admin}
            element={
              <Navigate
                replace
                to={selectedCompany ? routes.logs.list : routes.companies.list}
              />
            }
          />
          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route path={routes.drivers.edit} element={<DriverEditPage />} />
          <Route path={routes.drivers.list} element={<DriversListPage />} />
          <Route path={routes.vehicles.list} element={<VehiclesListPage />} />
          <Route path={routes.vehicles.edit} element={<VehiclesEditPage />} />
          <Route path={routes.companies.list} element={<Companies />} />
          <Route path={routes.companies.edit} element={<CompanyEdit />} />
          <Route path={routes.logs.details} element={<LogPage />} />
          <Route path={routes.logs.edit} element={<LogEditPage />} />
          <Route path={routes.logs.list} element={<LogListPage />} />
          <Route path={routes.reports.list} element={<ReportListPage />} />
          <Route
            path={routes.fmcsaReports.list}
            element={<FMCSAReportListPage />}
          />

          <Route
            path={routes.changePassword}
            element={<PasswordChangePage />}
          />
          <Route
            path={routes.logs.unidentifiedDriving}
            element={<UnidentifiedDrivingPage />}
          />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    if (
      currentUser?.role &&
      [Roles.OWNER, Roles.ADMIN_USER, Roles.USER].includes(currentUser.role)
    ) {
      return (
        <>
          {[Roles.OWNER, Roles.ADMIN_USER].includes(currentUser.role) && (
            <>
              <Route
                path={routes.companyUsers.create}
                element={<CompanyUserCreatePage />}
              />
            </>
          )}
          {[Roles.OWNER, Roles.ADMIN_USER, Roles.USER].includes(
            currentUser.role,
          ) && (
            <>
              <Route path={routes.elds.list} element={<EldsListPage />} />

              <Route
                path={routes.companyUsers.list}
                element={<CompanyUsersListPage />}
              />
              <Route
                path={routes.fmcsaReports.list}
                element={<FMCSAReportListPage />}
              />
              <Route
                path={routes.companyUsers.edit}
                element={<CompanyUserEditPage />}
              />
            </>
          )}
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.drivers.list} />}
          />
          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route path={routes.drivers.edit} element={<DriverEditPage />} />
          <Route path={routes.drivers.list} element={<DriversListPage />} />
          <Route path={routes.vehicles.list} element={<VehiclesListPage />} />
          <Route path={routes.vehicles.edit} element={<VehiclesEditPage />} />
          <Route
            path={routes.companies.ownerEdit}
            element={<OwnerCompanyEdit />}
          />
          <Route path={routes.logs.details} element={<LogPage />} />
          <Route path={routes.logs.edit} element={<LogEditPage />} />
          <Route path={routes.logs.list} element={<LogListPage />} />
          <Route path={routes.reports.list} element={<ReportListPage />} />
          <Route
            path={routes.changePassword}
            element={<PasswordChangePage />}
          />
          <Route
            path={routes.logs.unidentifiedDriving}
            element={<UnidentifiedDrivingPage />}
          />
          <Route path="*" element={<Navigate to={routes.drivers.list} />} />
        </>
      );
    }
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout isAuthenticated={isAuthenticated}>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
  selectedCompany: state.company.selectedCompany,
  timezone: state.user.timezone,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onCompanyFetch: (id: number) => dispatch(companyService.fetchCompany(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
