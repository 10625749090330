import * as actionTypes from './actionTypes';
import { LogGroupActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { LogGroup } from '../../domain/LogGroup';
import { ListResults } from '../../common/List/List';

export type LogGroupStateType = {
  logGroups: LogGroup[];
  logGroupsLoading: boolean;
  logGroupsError: HttpError;
  unidentifiedDrivings: ListResults<LogGroup> | null;
  unidentifiedDrivingsLoading: boolean;
  unidentifiedDrivingsError: HttpError;
  editLogGroupsLoading: boolean;
  editLogGroupsSuccess: boolean;
  editLogGroupsError: HttpError;
  deleteLogGroupsLoading: boolean;
  deleteLogGroupsSuccess: boolean;
  deleteLogGroupsError: HttpError;
  assignLogLoading: boolean;
  assignLogSuccess: boolean;
  assignLogError: HttpError;
};

export type LogGroupActionType = LogGroupStateType & {
  type: LogGroupActionTypes;
};

export const initialState: LogGroupStateType = {
  logGroups: [],
  logGroupsLoading: true,
  logGroupsError: null,
  unidentifiedDrivings: null,
  unidentifiedDrivingsLoading: true,
  unidentifiedDrivingsError: null,
  editLogGroupsLoading: false,
  editLogGroupsSuccess: false,
  editLogGroupsError: null,
  deleteLogGroupsLoading: false,
  deleteLogGroupsSuccess: false,
  deleteLogGroupsError: null,
  assignLogLoading: false,
  assignLogSuccess: false,
  assignLogError: null,
};

const editLogGroupsStart = (state: LogGroupStateType): LogGroupStateType => ({
  ...state,
  editLogGroupsLoading: true,
});

const editLogGroupsSuccess = (state: LogGroupStateType): LogGroupStateType => ({
  ...state,
  editLogGroupsLoading: false,
  editLogGroupsError: null,
  editLogGroupsSuccess: true,
});

const editLogGroupsFail = (
  state: LogGroupStateType,
  action: LogGroupActionType,
): LogGroupStateType => ({
  ...state,
  editLogGroupsError: action.editLogGroupsError,
  editLogGroupsLoading: false,
});

const editLogGroupsReset = (state: LogGroupStateType): LogGroupStateType => ({
  ...state,
  editLogGroupsLoading: false,
  editLogGroupsSuccess: false,
  editLogGroupsError: null,
});

const deleteLogGroupsStart = (state: LogGroupStateType): LogGroupStateType => ({
  ...state,
  deleteLogGroupsLoading: true,
});

const deleteLogGroupsSuccess = (
  state: LogGroupStateType,
): LogGroupStateType => ({
  ...state,
  deleteLogGroupsLoading: false,
  deleteLogGroupsError: null,
  deleteLogGroupsSuccess: true,
});

const deleteLogGroupsFail = (
  state: LogGroupStateType,
  action: LogGroupActionType,
): LogGroupStateType => ({
  ...state,
  deleteLogGroupsError: action.deleteLogGroupsError,
  deleteLogGroupsLoading: false,
});

const fetchLogGroupsStart = (state: LogGroupStateType): LogGroupStateType => ({
  ...state,
  logGroupsLoading: true,
});

const fetchLogGroupsSuccess = (
  state: LogGroupStateType,
  action: LogGroupActionType,
): LogGroupStateType => ({
  ...state,
  logGroupsLoading: false,
  logGroupsError: null,
  logGroups: action.logGroups,
  deleteLogGroupsSuccess: false,
});

const fetchLogGroupsFail = (
  state: LogGroupStateType,
  action: LogGroupActionType,
): LogGroupStateType => ({
  ...state,
  logGroupsError: action.logGroupsError,
  logGroupsLoading: false,
  deleteLogGroupsSuccess: false,
});

const fetchUnidentifiedDrivingsStart = (
  state: LogGroupStateType,
): LogGroupStateType => ({
  ...state,
  unidentifiedDrivingsLoading: true,
});

const fetchUnidentifiedDrivingsSuccess = (
  state: LogGroupStateType,
  action: LogGroupActionType,
): LogGroupStateType => ({
  ...state,
  unidentifiedDrivingsLoading: false,
  unidentifiedDrivingsError: null,
  unidentifiedDrivings: action.unidentifiedDrivings,
  assignLogSuccess: false,
});

const fetchUnidentifiedDrivingsFail = (
  state: LogGroupStateType,
  action: LogGroupActionType,
): LogGroupStateType => ({
  ...state,
  unidentifiedDrivingsError: action.unidentifiedDrivingsError,
  unidentifiedDrivingsLoading: false,
});

const assignLogStart = (state: LogGroupStateType): LogGroupStateType => ({
  ...state,
  assignLogLoading: true,
});

const assignLogSuccess = (state: LogGroupStateType): LogGroupStateType => ({
  ...state,
  assignLogLoading: false,
  assignLogError: null,
  assignLogSuccess: true,
});

const assignLogFail = (
  state: LogGroupStateType,
  action: LogGroupActionType,
): LogGroupStateType => ({
  ...state,
  assignLogError: action.assignLogError,
  assignLogLoading: false,
});

const assignLogReset = (state: LogGroupStateType): LogGroupStateType => ({
  ...state,
  assignLogLoading: false,
  assignLogSuccess: false,
  assignLogError: null,
});

const logout = (): LogGroupStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: LogGroupActionType) => {
  switch (action.type) {
    case actionTypes.EDIT_LOG_GROUPS_START:
      return editLogGroupsStart(state);
    case actionTypes.EDIT_LOG_GROUPS_SUCCESS:
      return editLogGroupsSuccess(state);
    case actionTypes.EDIT_LOG_GROUPS_FAIL:
      return editLogGroupsFail(state, action);
    case actionTypes.EDIT_LOG_GROUPS_RESET:
      return editLogGroupsReset(state);
    case actionTypes.DELETE_LOG_GROUPS_START:
      return deleteLogGroupsStart(state);
    case actionTypes.DELETE_LOG_GROUPS_SUCCESS:
      return deleteLogGroupsSuccess(state);
    case actionTypes.DELETE_LOG_GROUPS_FAIL:
      return deleteLogGroupsFail(state, action);
    case actionTypes.FETCH_LOG_GROUPS_START:
      return fetchLogGroupsStart(state);
    case actionTypes.FETCH_LOG_GROUPS_SUCCESS:
      return fetchLogGroupsSuccess(state, action);
    case actionTypes.FETCH_LOG_GROUPS_FAIL:
      return fetchLogGroupsFail(state, action);
    case actionTypes.FETCH_UNIDENTIFIED_DRIVINGS_START:
      return fetchUnidentifiedDrivingsStart(state);
    case actionTypes.FETCH_UNIDENTIFIED_DRIVINGS_SUCCESS:
      return fetchUnidentifiedDrivingsSuccess(state, action);
    case actionTypes.FETCH_UNIDENTIFIED_DRIVINGS_FAIL:
      return fetchUnidentifiedDrivingsFail(state, action);
    case actionTypes.ASSIGN_LOG_START:
      return assignLogStart(state);
    case actionTypes.ASSIGN_LOG_SUCCESS:
      return assignLogSuccess(state);
    case actionTypes.ASSIGN_LOG_FAIL:
      return assignLogFail(state, action);
    case actionTypes.ASSIGN_LOG_RESET:
      return assignLogReset(state);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;
