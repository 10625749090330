import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  createUserStart,
  createUserSuccess,
  createUserFail,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchCurrentUserFail,
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFail,
  fetchAllUsersFail,
  fetchAllUsersStart,
  fetchAllUsersSuccess,
  updateUserInfoStart,
  updateUserInfoSuccess,
  updateUserInfoFail,
  changeUserPasswordStart,
  changeUserPasswordSuccess,
  changeUserPasswordFail,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { User } from '../../domain/User';
import { setSelectedSettings } from '../company/actions';
import { ReportType } from '../../domain/Report';
import { LogTypeFilter } from '../../domain/LogGroup';
import { Roles } from '../../domain/Role';
import { DateType } from '../../component/Admin/Log/LogList/LogList';
import { UnidentifiedDrivingStatus } from '../../component/Admin/Log/UnidentifiedDrivingList/UnidentifiedDrivingList';

export type SavedUserFilters = {
  limit?: number;
  listName?: string;
  driverId?: string;
  vehicleId?: string;
  dateFrom?: string;
  dateTo?: string;
  logType?: LogTypeFilter;
  page?: number;
  reportType?: ReportType;
  userRole?: Roles;
  languageId?: string;
  dateFilter?: DateType;
  status?: UnidentifiedDrivingStatus;
};

export type UserCreateRequest = {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

export type UserUpdateRequest = {
  id: number;
  firstName: string;
  lastName: string;
  role: string;
};

export type UserUpdateInfoRequest = {
  firstName: string;
  lastName: string;
};

export type UserChangePasswordRequest = {
  currentPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

const API_URL = '/users';

export const fetchUser =
  (id: number, companyUser?: boolean) => (dispatch: Dispatch) => {
    dispatch(fetchUserStart());
    return axios
      .get(companyUser ? `${API_URL}/company/${id}` : `${API_URL}/${id}`)
      .then((response) => {
        dispatch(fetchUserSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUserFail(err.response.data.error));
      });
  };

export const fetchUsers = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchUsersStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUsersFail(err.response.data.error));
    });
};

export const fetchCompanyUsers =
  (params: ListParams) => (dispatch: Dispatch) => {
    dispatch(fetchUsersStart());
    return axios
      .get(`${API_URL}/company`, { params })
      .then((response) => {
        dispatch(fetchUsersSuccess(response?.data));
      })
      .catch((err) => {
        dispatch(fetchUsersFail(err?.response?.data?.error));
      });
  };

export const fetchAllUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchAllUsersStart());
  return axios
    .get(API_URL)
    .then((response) => {
      dispatch(fetchAllUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllUsersFail(err.response.data.error));
    });
};

export const createUser =
  (inputs: UserCreateRequest, intl: IntlShape, companyUser?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(createUserStart());
    return axios
      .post(companyUser ? `${API_URL}/company-user` : API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createUserSuccess(response.data));
        showToast(
          translate(
            intl,
            companyUser ? 'SUCCESS.USER_CREATE' : 'SUCCESS.ADMIN_CREATE',
          ),
          'success',
        );
      })
      .catch((err) => {
        dispatch(createUserFail(err.response.data.message));
      });
  };

export const updateUser =
  (inputs: UserUpdateRequest, intl: IntlShape, companyUser?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    return axios
      .patch(
        companyUser
          ? `${API_URL}/company-user/${inputs.id}`
          : `${API_URL}/${inputs.id}`,
        {
          ...inputs,
        },
      )
      .then(() => {
        dispatch(updateUserSuccess());
        showToast(
          translate(
            intl,
            companyUser ? 'SUCCESS.USER_UPDATE' : 'SUCCESS.ADMIN_UPDATE',
          ),
          'success',
        );
      })
      .catch((err) => {
        dispatch(updateUserFail(err.response.data.message));
      });
  };

export const updateUserInfo =
  (inputs: UserUpdateInfoRequest) => (dispatch: Dispatch) => {
    dispatch(updateUserInfoStart());
    return axios
      .patch(`${API_URL}/update-info`, {
        ...inputs,
      })
      .then((response) => {
        dispatch(updateUserInfoSuccess());
        dispatch(fetchCurrentUserSuccess(response.data));
      })
      .catch((err) => {
        dispatch(updateUserInfoFail(err.response.data.message));
      });
  };

export const deleteUser =
  (id: number, intl: IntlShape, companyUser?: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(deleteUserStart());
    return axios
      .delete(
        companyUser ? `${API_URL}/company-user/${id}` : `${API_URL}/${id}`,
      )
      .then(() => {
        dispatch(deleteUserSuccess());
        showToast(
          translate(
            intl,
            companyUser ? 'SUCCESS.USER_DELETE' : 'SUCCESS.ADMIN_DELETE',
          ),
          'success',
        );
      })
      .catch((err) => {
        dispatch(deleteUserFail(err.response.data.message));
      });
  };

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());

  return axios
    .get(`${API_URL}/me`)
    .then((response) => {
      const user = response.data as User;
      dispatch(fetchCurrentUserSuccess(user));

      if (user.settings) {
        dispatch(setSelectedSettings(user.settings));
      }
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
    });
};

export const changeUserPassword =
  (inputs: UserChangePasswordRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(changeUserPasswordStart());
    return axios
      .patch(`${API_URL}/change-password`, { ...inputs })
      .then(() => {
        dispatch(changeUserPasswordSuccess());
        showToast(translate(intl, 'SUCCESS.PASSWORD_CHANGE'), 'success');
      })
      .catch((err) => {
        dispatch(changeUserPasswordFail(err?.response?.data?.message));
      });
  };
