import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './Label.module.scss';

type Props = {
  content?: string;
  children?: ReactNode;
  variant: string;
  className?: string;
  onClick?: (event: any) => void;
  onMouseEnter?: (event: any) => void;
  onMouseLeave?: (event: any) => void;
};

export const Label = ({
  content,
  className,
  variant,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props) => (
  <span
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className={cx(styles.label, className, {
      [styles.success]: variant === 'success',
      [styles.error]: variant === 'error',
      [styles.info]: variant === 'info',
      [styles.warning]: variant === 'warning',
      [styles.default]: variant === 'default',
      [styles.action]: variant === 'action',
      [styles.intermediate]: variant === 'intermediate',
      [styles.malfunction]: variant === 'malfunction',
      [styles.diagnostic]: variant === 'diagnostic',
      [styles.clickable]: !!onClick,
    })}
  >
    {content}
    {children}
  </span>
);

export default Label;
